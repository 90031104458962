ul {
  list-style-type: none;
  /* padding: 0; */
  /* margin: 0; */
}

.btn-sm-angkor {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.col-cart-angkor {
  padding-left: 4px;
  padding-right: 4px;
}



