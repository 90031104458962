html {
  position: relative;
  min-height: 100%;
}

body {
  font-size: 0.875rem;
  margin-bottom: 48px;
}

body > .container {
  padding: 15px 15px 0;
}

code {
  font-size: 80%;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.nav-item {
  flex: 0 0 auto;
}
