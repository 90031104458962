.menu-item-picture {
  position: relative;
  display: block;
  z-index: 1;
}

.menu-item-sold-out-image {
  position: absolute;
  margin-top: 35%;
  margin-left: 50%;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: 2;
}