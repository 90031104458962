.hero-img {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.hero-img .title {
  text-align: center;
  width: 100%;
  margin: 5% 5%;
  position: absolute;
  z-index: 1;
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
  color: #fff;
  padding: 0 15px;
  margin: 0;
  word-wrap: break-word;
}

.hero-img- .slug {
  text-align: center;
  width: 100%;
  margin: 12% 5%;
  position: absolute;
  z-index: 2;
}

.staging {
  min-height: 630px;
  box-sizing: border-box;
  padding-top: 70px;
  background-color: #000;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.staging-headlines {
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.staging-headlines h1 {
    font-size: 40px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    padding: 0 15px;
    margin: 0;
    word-wrap: break-word;
}

.staging-headlines h2 {
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 20px;
    padding: 0 15px;
    color: #fff;
}

.staging-background {
    content: "";
    background-size: cover;
    background-position: center;
    opacity: 1.0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

section {
    margin: 40px 15px;
    background-color: #fff;
}

section h2 {
    color: #4d4d4d;
    margin: 0;
    line-height: 1em;
    text-transform: capitalize;
    word-wrap: break-word;
}

section hr {
    display: block;
    margin: 30px auto;
    width: 80px;
    height: 3px;
    background-color: #df733c;
    border: none;
}

.wsite-multicol {
    position: relative;
    direction: ltr;
}

.wsite-multicol-table {
    position: relative;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
}

.wsite-multicol-col {
    vertical-align: top;
    margin: 0 !important;
    border: 0 !important;
    padding: 0;
    -moz-box-sizing: border-box;
}

.cta {
  font-size: 24px;
  font-weight: 400;
}