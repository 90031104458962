ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .time-slot {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    float: left;
  }
  .time-slot li.disable-slot {
    background: #948d86 !important;
    cursor: text;
  }

  .time-slot li {
    background: none repeat scroll 0 0 #a8ad00 !important;
    cursor: pointer;
    width: 25%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }

  .time-slot li:hover {
    background: none repeat scroll 0 0 #612c51 !important;
    cursor: pointer;
    width: 25%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }

  .time-slot li.time-selected {
    background: none repeat scroll 0 0 #612c51 !important;
    color: #ffffff;
    border: 2px #ffffff solid;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .time-slot {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    float: left;
  }
  .time-slot li.disable-slot {
    background: #948d86 !important;
    cursor: text;
  }
  .time-slot li {
    background: none repeat scroll 0 0 #a8ad00 !important;
    cursor: pointer;
    width: 20%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }
  .time-slot li:hover {
    background: none repeat scroll 0 0 #612c51 !important;
    cursor: pointer;
    width: 20%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }
  .time-slot li.time-selected {
    background: none repeat scroll 0 0 #612c51 !important;
    color: #ffffff;
    border: 2px #ffffff solid;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .time-slot {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    float: left;
  }
  .time-slot li.disable-slot {
    background: #948d86 !important;
    cursor: text;
  }

  .time-slot li {
    background: none repeat scroll 0 0 #a8ad00 !important;
    cursor: pointer;
    width: 10%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }

  .time-slot li:hover {
    background: none repeat scroll 0 0 #612c51 !important;
    cursor: pointer;
    width: 10%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }

  .time-slot li.time-selected {
    background: none repeat scroll 0 0 #612c51 !important;
    color: #ffffff;
    border: 2px #ffffff solid;
  }
}

@media (min-width: 1200px) {
  .time-slot {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    float: left;
  }
  .time-slot li.disable-slot {
    background: #948d86 !important;
    cursor: text;
  }

  .time-slot li {
    background: none repeat scroll 0 0 #a8ad00 !important;
    cursor: pointer;
    width: 10%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }

  .time-slot li:hover {
    background: none repeat scroll 0 0 #612c51 !important;
    cursor: pointer;
    width: 10%;
    display: block;
    height: 20px;
    float: left;
    color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    height: 35px;
    line-height: 2.5em;
  }

  .time-slot li.time-selected {
    background: none repeat scroll 0 0 #612c51 !important;
    color: #ffffff;
    border: 2px #ffffff solid;
  }
}
